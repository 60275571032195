import axios from '@axios'
import baseConfig from '@/config/index'
const REPORT_SERVICE_PATH = baseConfig.REPORT_SERVICE_PATH
const TICKET_API_SERVICE_PATH = baseConfig.TICKET_API_SERVICE_PATH

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchReportNames(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/report/getReportNames', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReportData(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/report/getReportData', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}
